body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a.chakra-tabs__tab.active {
  --tabs-color: var(--chakra-colors-blue-600) !important;
  border-color: currentColor !important;
}

a.chakra-tabs__tab {
  --tabs-color: var(--chakra-colors-black-600) !important;
  border-color: rgb(226, 232, 240) !important;
}

.custom-date-picker {
  height: 40px;
  text-align: center;
  width: 200px;
  color: var(--chakra-colors-blue-600);
  border-radius: var(--chakra-radii-md);
  border-color: var(--chakra-colors-blue-600) !important;
  border-width: 1px;
  background: transparent;
  padding-left: var(--chakra-space-4);
  padding-right: var(--chakra-space-4);
  font-weight:  var(--chakra-fontWeights-semibold);
  outline: none;
}

.custom-date-picker:focus-visible {
  outline-color: var(--chakra-colors-blue-600) !important;
}