:root {

    // Blue
    --blue-01: #4318FF;
    --blue-01-o5: rgba(67, 24, 255, 0.8);
    --blue-02: #0F62FE;
    --blue-03: #05a9d7;

    // White
    --white-01: #ffffff;
    --white-02: #F2F4F8;

    // Black
    --black-01: #21272A;

    // Gray
    --gray-01: #DDE1E6;
    --gray-02: #697077;

    // Red
    --red-01: #DA1E28;

    // Overwrite
    --pagination-color: var(--chakra-colors-blue-600)
}